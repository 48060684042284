import { IResourceComponentsProps } from "@refinedev/core";
import React, { useState } from "react";

import { Edit, ListButton, RefreshButton, useForm } from "@refinedev/antd";
import { Alert, Button } from "antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { ICard } from "@/interfaces";
import { CardForm } from "components/cards/CardForm";

export const CardEdit: React.FC<IResourceComponentsProps> = () => {
    const [isDeprecated, setIsDeprecated] = useState(false);
    const { formProps, saveButtonProps, queryResult } = useForm<ICard>({
        liveMode: "manual",
        onLiveEvent: () => {
            setIsDeprecated(true);
        },
    });

    const handleRefresh = () => {
        queryResult?.refetch();
        setIsDeprecated(false);
    };

    return (
        <Edit
            saveButtonProps={saveButtonProps}
            headerProps={{
                extra: (
                    <>
                        <ListButton />
                        <RefreshButton onClick={handleRefresh} />
                    </>
                ),
            }}
        >
            {isDeprecated && (
                <Alert
                    message="This card is changed. Reload to see it's latest version."
                    type="warning"
                    style={{
                        marginBottom: 20,
                    }}
                    action={
                        <Button
                            onClick={handleRefresh}
                            size="small"
                            type="dashed"
                        >
                            Refresh
                        </Button>
                    }
                />
            )}

                {CardForm(formProps, queryResult)}
        </Edit>
    );
};
