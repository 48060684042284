import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";

import "react-mde/lib/styles/css/react-mde-all.css";

import { useSelect } from "@refinedev/antd";
import { RcFile, UploadFile } from "antd/lib/upload/interface";

import {
  DatePicker,
  Form,
  FormProps,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";

import { GetOneResponse } from "@refinedev/core";
import { QueryObserverResult } from "@tanstack/query-core";

import { ICard, ICategory } from "@/interfaces";
import { useState } from "react";
import { normalizeFile, supabaseClient } from "utility";
import { resizeFile } from "utility/image-op";

dayjs.extend(weekday)
dayjs.extend(localeData)

export function CardForm(
  formProps: FormProps,
  queryResult:
    | QueryObserverResult<GetOneResponse<ICard>, unknown>
    | undefined = undefined
) {
  const postData = queryResult?.data?.data;
  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: "categories",
    optionLabel: "name",

    pagination: {
      mode: "server"
    }
  });
  const fileList: UploadFile[] | null = postData?.image
    ? [postData?.image as UploadFile]
    : null;

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  return (
    <Form {...formProps} layout="vertical">
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Category"
        name={["category"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select {...categorySelectProps} />
      </Form.Item>
      <Form.Item
        label="Ebay Price"
        name="price"
      >
        <InputNumber prefix="$" />
      </Form.Item>
      <Form.Item label="Currency" name="currency">
        <Radio.Group>
          <Radio.Button value="USD">USD</Radio.Button>
          <Radio.Button value="CAD">CAD</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Start Range" name="start_range">
        <InputNumber prefix="$" />
      </Form.Item>
      <Form.Item label="Low Range" name="low_range">
        <InputNumber prefix="$" />
      </Form.Item>
      <Form.Item label="Is Sold" name="is_sold" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="Sold Price" name="sold_price">
        <InputNumber prefix="$" />
      </Form.Item>
      {/* ebay" | "site" | "store" | "expo */}
      <Form.Item label="Sold At" name="sold_at">
        <Radio.Group>
          <Radio.Button value="ebay">Ebay</Radio.Button>
          <Radio.Button value="site">Site</Radio.Button>
          <Radio.Button value="store">Store</Radio.Button>
          <Radio.Button value="expo">Expo</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Sold On"
        name="sold_date"
        getValueProps={(value) => ({
          value: value ? dayjs(value) : null,
        })}
        
      >
        <DatePicker format={'YYYY/MM/DD'} />
      </Form.Item>

      <Form.Item label="Ebay Item Number" name="ebay_item_number">
        <Input />
      </Form.Item>
      <Form.Item label="Image">
        <Form.Item
          name="image"
          valuePropName="file"
          normalize={normalizeFile}
          noStyle
        >
          <Upload.Dragger
            name="file"
            listType="picture"
            defaultFileList={fileList ? fileList : []}
            customRequest={async ({ file, onError, onSuccess }) => {
              const resizedFile = await resizeFile(file as Blob);
              const rcFile = file as RcFile;
              const fileUrl = `img/${Date.now() + rcFile.name}`;
              const { error } = await supabaseClient.storage
                .from("cards")
                .upload(fileUrl, resizedFile as any, {
                  cacheControl: "3600",
                  upsert: true,
                });

              if (error) {
                return onError?.(error);
              }
              const { data } = supabaseClient.storage
                .from("cards")
                .getPublicUrl(fileUrl);

              if (!data) {
                return onError?.(new Error("No data returned"));
              }

              onSuccess?.({ url: data?.publicUrl }, new XMLHttpRequest());
            }}
          >
            <p className="ant-upload-text">Drag & drop a file in this area</p>
          </Upload.Dragger>
        </Form.Item>
      </Form.Item>
      <Form.Item label="Note" name="note">
        <ReactMde
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          generateMarkdownPreview={(markdown) =>
            Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
          }
        />
      </Form.Item>

      <Form.Item label="Enabled" name="enabled" valuePropName="checked">
        <Switch />
      </Form.Item>
    </Form>
  );
}
