import { Create, useForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";

import "react-mde/lib/styles/css/react-mde-all.css";

import { ICard } from "@/interfaces";
import { CardForm } from "components/cards/CardForm";

export const CardCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ICard>();

  return (
    <Create saveButtonProps={saveButtonProps}>{CardForm(formProps)}</Create>
  );
};
