import {
  CheckCircleTwoTone,
  CloseCircleOutlined,
  EyeInvisibleTwoTone,
  EyeTwoTone
} from "@ant-design/icons";

export const SoldIcon = () => <CheckCircleTwoTone twoToneColor="#52c41a" rev="" />;
export const UnsoldIcon = () => <CloseCircleOutlined twoToneColor="#FF0000" rev="" />;
export const ListedIcon = () => <EyeTwoTone twoToneColor="#52c41a" />;
export const UnlistedIcon = () => (
  <EyeInvisibleTwoTone twoToneColor="#FF0000" />
);
