import { ICategory } from "@/interfaces";
import { EditButton, List, ShowButton, getDefaultSortOrder, useTable } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { Space, Table } from "antd";

export const CategoryList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorters: sorter } = useTable<ICategory>({
    meta: {
      select: "*",
    },

    sorters: {
      initial: [
        {
          field: "id",
          order: "asc",
        },
      ]
    }
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          key="id"
          dataIndex="id"
          title="ID"
          sorter
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
        />
        <Table.Column key="name" dataIndex="name" title="Name" sorter />
        <Table.Column<ICategory>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
