import { ThemedLayoutV2, useNotificationProvider } from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";
import { Authenticated, ErrorComponent, Refine } from "@refinedev/core";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { dataProvider, liveProvider } from "@refinedev/supabase";
import { App as AntdApp, Layout } from "antd";
import { Header } from "components";
import { CardCreate, CardEdit, CardList, CardShow } from "pages/cards";
import {
  CategoryCreate,
  CategoryEdit,
  CategoryList,
  CategoryShow,
} from "pages/categories";
import { Login } from "pages/login";
import { CardReport } from "pages/sold-on-report";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { supabaseClient } from "utility";
import authProvider from "./authProvider";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { Settings } from "./pages/settings";

export const SiteTitle = () => {
  return <span style={{
    color: "red"
  }}>CNC Single Cards</span>;
};
function App() {
  return (
    <BrowserRouter>
      <ColorModeContextProvider>
        <AntdApp>
          <Refine
            notificationProvider={useNotificationProvider}
            dataProvider={dataProvider(supabaseClient)}
            liveProvider={liveProvider(supabaseClient)}
            authProvider={authProvider}
            routerProvider={routerBindings}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              projectId: "LC19X0-75DJFl-RpWxBT",
              liveMode: "auto",
            }}
            resources={[
              {
                name: "cards",
                list: "/cards",
                create: "/cards/create",
                edit: "/cards/:id",
                show: "/cards/:id/show",
              },
              {
                name: "categories",
                list: "/categories",
                create: "/categories/create",
                edit: "/categories/:id",
                show: "/categories/:id/show",
              },
              {
                name: "reports",
                list: "/reports",
              },
              {
                name: "settings",
                list: "/settings",
              },
            ]}
          >
            <Routes>
              {/* prefix `resources` paths. */}
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                    <ThemedLayoutV2 Header={Header} Title={SiteTitle}>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route
                  index
                  element={<NavigateToResource resource="cards" />}
                />

                <Route path="cards">
                  <Route index element={<CardList />} />
                  <Route path="show/:id" element={<CardShow />} />
                  <Route path="create" element={<CardCreate />} />
                  <Route path=":id" element={<CardEdit />} />
                </Route>
                <Route path="categories">
                  <Route index element={<CategoryList />} />
                  <Route path="show/:id" element={<CategoryShow />} />
                  <Route path="create" element={<CategoryCreate />} />
                  <Route path=":id" element={<CategoryEdit />} />
                </Route>
              </Route>
              <Route
                path="reports"
                element={
                  <ThemedLayoutV2 Header={Header} Title={SiteTitle}>
                    <CardReport />
                  </ThemedLayoutV2>
                }
              />
              <Route
                path="settings"
                element={
                  <ThemedLayoutV2 Header={Header} Title={SiteTitle}>
                    <Settings />
                  </ThemedLayoutV2>
                }
              />
              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource resource="cards" />
                  </Authenticated>
                }
              >
                <Route
                  path="/login"
                  element={
                    <Layout>
                      <Login />
                    </Layout>
                  }
                />
              </Route>

              <Route
                element={
                  <Authenticated>
                    <ThemedLayoutV2 Header={Header} Title={SiteTitle}>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>
            <UnsavedChangesNotifier />
            <DocumentTitleHandler />
          </Refine>
        </AntdApp>
      </ColorModeContextProvider>
    </BrowserRouter>
  );
}

export default App;
