import { AuthPage } from "@refinedev/core";
import { Card, Col, Row } from "antd";
import "./styles.css";

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export const Login: React.FC = () => {
  return (

      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <Card headStyle={{}}>
              <AuthPage
                type="login"
                registerLink={false}
                forgotPasswordLink={false}
                formProps={{
                  style:{
                    // color: "black"
                  }
                }}
              />
            </Card>
          </div>
        </Col>
      </Row>

  );
};
