import { ICard } from "@/interfaces";
import { DateField, List, getDefaultSortOrder, useTable } from "@refinedev/antd";
import { CrudFilters, HttpError, IResourceComponentsProps } from "@refinedev/core";
import { Card, Col, DatePicker, Form, Row, Table } from "antd";
import dayjs from "dayjs";

export const CardReport: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorters: sorter, searchFormProps } = useTable<
    ICard,
    HttpError,
    { sold_date: string }
  >({
    resource: "cards",

    onSearch: ({ sold_date }) => {
      const cardFilters: CrudFilters = [];
      const from_date_value = sold_date
        ? dayjs(sold_date).subtract(1, "day").toISOString()
        : null;
      const to_date_value = sold_date
        ? dayjs(sold_date).add(1, "day").toISOString()
        : null;
      if (from_date_value && to_date_value) {
        cardFilters.push({
          field: "sold_date",
          operator: "lt",
          value: to_date_value,
        });

        cardFilters.push({
          field: "sold_date",
          operator: "gt",
          value: from_date_value,
        });
      }

      cardFilters.push({
        field: "is_sold",
        operator: "eq",
        value: true,
      });

      console.log("cardFilters", cardFilters);

      return cardFilters;
    },

    pagination: {
      pageSize: 10
    },

    filters: {
      permanent: [
        {
          field: "is_sold",
          operator: "eq",
          value: true,
        },
      ]
    },

    sorters: {
      initial: [
        {
          field: "sold_date",
          order: "desc",
        },
      ]
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card title="Filter List">
          <Form
            {...searchFormProps}
            onValuesChange={() => {
              searchFormProps.form?.submit();
              console.log(searchFormProps.form ? true : false);
            }}
          >
            <Form.Item name="sold_date" label="Date">
              <DatePicker />
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col span={24}>
        <List title="Sold Cards">
          <Table
            {...tableProps}
            rowKey="id"
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    {tableProps.dataSource?.reduce(
                      (acc: number, item: ICard) => acc + item.sold_price,
                      0
                    )}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          >
            <Table.Column
              key="id"
              dataIndex="id"
              title="ID"
              sorter
              defaultSortOrder={getDefaultSortOrder("id", sorter)}
            />
            <Table.Column key="name" dataIndex="name" title="name" sorter />
            <Table.Column
              key="sold_at"
              dataIndex="sold_at"
              title="Sold At"
              sorter
            />
            <Table.Column
              key="sold_price"
              dataIndex="sold_price"
              title="Sold Price"
              sorter
            />
            <Table.Column
              key="sold_date"
              dataIndex="sold_date"
              title="Sold Date"
              sorter
              render={(value) =>
                value ? <DateField format="LLL" value={value} /> : null
              }
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
