import { ICard } from "@/interfaces";
import { DollarCircleOutlined, LinkOutlined } from "@ant-design/icons";
import { EditButton } from "@refinedev/antd";
import { Card, Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { SoldIcon, UnsoldIcon } from "./icons";

type SingleCardProps = {
  record: ICard;
  onSelect?: Function;
  editDrawerShow?: Function;
};

export const SingleCard: React.FC<SingleCardProps> = ({
  record,
  onSelect,
  editDrawerShow,
}) => {
  const { Meta } = Card;
  const id = record?.id || "";
  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
    onSelect && onSelect(id, e.target.checked);
  };
  return (
    <Card
      style={{
        margin: 8,
        maxWidth: 300,
        display: "block",
      }}
      actions={[
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: 10
        
        }}>
          <Checkbox onChange={onChange} checked={record?.enabled}>
            Enabled
          </Checkbox>
          <EditButton resource="cards" recordItemId={id} />
          {editDrawerShow ? (
            <EditButton
              hideText
              size="small"
              recordItemId={record.id}
              onClick={() => editDrawerShow(id)}
              icon={<DollarCircleOutlined />}
            />
          ) : (
            <></>
          )}
        </div>,
      ]}
      hoverable
      cover={
        <img
          alt="example"
          src={
            record?.image
              ? (record?.image.backup_url || record?.image.url) 
              : "https://via.placeholder.com/240x135/09f/fff.png"
          }
          style={{ objectFit: "cover"}}
        />
      }
      onClick={editDrawerShow ? () => editDrawerShow(id) : undefined}
    >
      {/*        Category: ${categoryIsLoading ? "Loading..." : categoryData?.data.name} */}
      <Meta
        title={
          <small style={{ fontSize: "small" }}>
            {record?.id}) {record?.name}
          </small>
        }
        description={`Price: ${record?.currency} ${record?.start_range} to $${record?.low_range}
       Sold on:  ${record?.sold_at} for $${record?.sold_price}
       
      `}
      />
      <br />
      {record?.is_sold ? (
        <>
          <SoldIcon /> Sold on {record?.sold_at} for ${record?.sold_price}
        </>
      ) : (
        <>
          <UnsoldIcon /> Unsold
        </>
      )}
      {"   "}
      {record?.ebay_item_number && (
        <a
          target="_blank"
          href={
            record?.ebay_item_number
              ? "https://www.ebay.com/itm/" + record?.ebay_item_number
              : ""
          }
          rel="noreferrer"
        >
          <LinkOutlined key="setting" /> Ebay
        </a>
      )}
      {/* <br />
      {record?.enabled ? (
        <>
          <ListedIcon /> Enabled
        </>
      ) : (
        <>
          <UnlistedIcon /> Disabled
        </>
      )} */}
    </Card>
  );
};
