import { IFile } from "@/interfaces";
import eBayApi from "ebay-api";
import { MarketplaceId } from "ebay-api/lib/enums";
import { resizeFile } from "./image-op";
import { createNewCategory, supabaseClient } from "./supabaseClient";
// or:
// const eBayApi = require('ebay-api')

export const getItemFromEbay = async (ebayItemNumber: string) => {
  try {
    const eBay = new eBayApi({
      appId: process.env.REACT_APP_EBAY_APP_ID || "",
      certId: process.env.REACT_APP_EBAY_CERT_ID || "",
      sandbox: false,
      siteId: 0,
      marketplaceId: "EBAY_CA" as MarketplaceId,
    });
    // eBay.req.instance is AxiosInstance per default
    eBay.req.instance.interceptors.request.use((request) => {
      // Add Proxy
      request.url = "https://cnc-ebay.cloutsnchara.workers.dev/" + request.url;
      return request;
    });
    const item = await eBay.buy.browse.getItem(`v1|${ebayItemNumber}|0`);
    console.log(item);
    /**
     export interface ICard {
      is_sold: boolean;
  }
  
   // fetch categoryId or create a new categoryId
     * * */
    let categoryId = 30; // default categoryId is 12 for Trading Card Singles
    const { data } = await supabaseClient
      .from("categories")
      .select("*")
      .match({ ebay_category_id: item.categoryId });
    if (data && data.length > 0) {
      categoryId = data[0].id;
    } else {
      // create a new category object
      const itemCategoryPath = item.categoryPath.split("|");
      const newCategory = await createNewCategory(
        itemCategoryPath[itemCategoryPath.length - 1],
        item.categoryId
      );
      if (newCategory.data && newCategory.data?.length > 0) {
        categoryId = newCategory.data[0].id;
      }
    }

    // download image using fetch, resize image

    const blob = await fetch(item.image.imageUrl).then((r) => r.blob());
    const resizedImage = await resizeFile(blob);
    const fileName = `${ebayItemNumber}-${Date.now()}.jpeg`;
    let fileUrl = `img/${fileName}`;
    const { error } = await supabaseClient.storage
      .from("cards")
      .upload(fileUrl, resizedImage as any, {
        cacheControl: "3600",
        upsert: true,
      });

    if (error) {
      fileUrl = "";
      console.error(error);
    } else {
      const { data } = supabaseClient.storage
        .from("cards")
        .getPublicUrl(fileUrl);

      if (data) {
        fileUrl = data.publicUrl;
      }
    }

    return {
      name: item.title,
      price: item.price.value,
      currency: item.price.currency,
      image: {
        url: item.image.imageUrl,
        backup_url: fileUrl,
      } as IFile,
      category: categoryId,
      ebay_item_number: ebayItemNumber,
      is_sold: false,
      enabled: true,
    };
  } catch (e) {}
};
