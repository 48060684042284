
import { Edit, useForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import { Card, Col, Form, Input, Row } from "antd";

export const Settings: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<{
    discount_rate: number;
  }>({
    resource: "settings",
    action: "edit",
    id: 1,
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="Settings">
            <Form {...formProps} layout="vertical">
              <Form.Item
                name="discount_rate"
                label="Discount Rate"
                initialValue={queryResult?.data?.data?.discount_rate}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input maxLength={2} />
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Edit>
  );
};
