import { createClient } from "@refinedev/supabase";

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL || "";
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_ANON_KEY || "";

export const supabaseClient = createClient(SUPABASE_URL, SUPABASE_KEY, {
  db: {
    schema: "public",
  },
  auth: {
    persistSession: true,
  },
});

export const createNewCategory = async (
  category: string,
  ebay_category_id: number
) => {
  const { data, error } = await supabaseClient
    .from("categories")
    .insert({ name: category, ebay_category_id: ebay_category_id })
    .select()
    .single();
  return { data, error };
};
