import Resizer from "react-image-file-resizer";
import { supabaseClient } from "./supabaseClient";

export const resizeFile = (file: Blob) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      800,
      "JPEG",
      100,
      0,
      (uri) => {
        //resolve(uri);
        // resolve as a file
        resolve(uri)
        

      },
      "blob"
    );
  });

export const syncEbayImages = async () => {

  // get all cards records from supabase table
  // for each card record, download the image from ebay and resize it
  // upload the resized image to supabase storage
  // update the card record with the new image url

  const cards = await supabaseClient.from("cards").select("*");

  if(cards.error) {
    console.error(cards.error);
    return;
  }

  if(!cards.data) {
    console.log("No cards found");
    return;
  }

  // loop through each card record
  for(const card of cards.data) {

    const image = card.image;

    if(!image) {
      console.error("No image found for card", card.id);
      continue;
    }

    if(image.backup_url) {
      console.log("Image already downloaded for card", card.id);
      continue;
    }

    if(!image.url) {
      console.error("No image url found for card", card.id);
      continue;
    }

    // download image using fetch, resize image
    const blob = await fetch(image.url).then((r) => r.blob());
    const resizedImage = await resizeFile(blob);
    const fileName = `${card.ebay_item_number}-${Date.now()}.jpeg`;
    let fileUrl = `img/${fileName}`;
    const { error } = await supabaseClient.storage
      .from("cards")
      .upload(fileUrl, resizedImage as any, {
        cacheControl: "3600",
        upsert: true,
      });

    if (error) {
      fileUrl = "";
      console.error(error);
    }

    const { data } = await supabaseClient.storage
      .from("cards")
      .getPublicUrl(fileUrl);

    if (data) {
      fileUrl = data.publicUrl;
    }

    // update the card record with the new image url
    const { error: updateError } = await supabaseClient
      .from("cards")
      .update({ image: { ...image, backup_url: fileUrl } })
      .eq("id", card.id);

    if(updateError) {
      console.error(updateError);
    } else {
      console.log("Image downloaded and updated for card", card.id);
    }




  }

}