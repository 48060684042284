import { EditButton, ListButton, MarkdownField, RefreshButton, Show } from "@refinedev/antd";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import { Alert, Button, Typography } from "antd";

import { ICard } from "@/interfaces";
import { SingleCard } from "components/cards/SingleCard";
import { useState } from "react";

const { Title } = Typography;

export const CardShow: React.FC<IResourceComponentsProps> = () => {
  const [isDeprecated, setIsDeprecated] = useState(false);

  const { queryResult } = useShow<ICard>({
    liveMode: "manual",
    onLiveEvent: () => {
      setIsDeprecated(true);
    },
  });

  const { data, isLoading } = queryResult;
  const record = data?.data;

  const handleRefresh = () => {
    queryResult.refetch();
    setIsDeprecated(false);
  };

  return (
    <Show
      isLoading={isLoading}
      headerProps={{
        extra: (
          <>
            <EditButton />
            <ListButton />
            <RefreshButton onClick={handleRefresh} />
          </>
        ),
      }}
    >
      {isDeprecated && (
        <Alert
          message="This card is changed. Reload to see it's latest version."
          type="warning"
          style={{
            marginBottom: 20,
          }}
          action={
            <Button onClick={handleRefresh} size="small" type="dashed">
              Refresh
            </Button>
          }
        />
      )}


      {record && <SingleCard record={record} />}

      <Title level={5}>Note:</Title>
      <MarkdownField value={record?.note} />
    </Show>
  );
};
