import { ICard } from "@/interfaces";
import {
  FileDoneOutlined,
  FileExcelOutlined,
  LoadingOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  CreateButton,
  Edit,
  useDrawerForm,
  useSimpleList,
} from "@refinedev/antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  getDefaultFilter,
  useNavigation,
  useUpdateMany,
} from "@refinedev/core";
import {
  List as AntdList,
  Button,
  Col,
  Drawer,
  Form,
  Image,
  Input,
  InputNumber,
  Radio,
  Row,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import { SingleCard } from "components/cards/SingleCard";
import { CardCategoryFilter } from "components/cards/cardCategoryFilter";
import Papa from "papaparse";
import { useEffect, useReducer } from "react";
import { supabaseClient } from "utility";
import { getItemFromEbay } from "utility/getEbayItems";

const { Text } = Typography;

enum UploadState {
  PROGRESS = "progress",
  FAILED = "failed",
  SUCCESS = "success",
  UPLOADED = "uploaded",
  UPDATED = "updated",
  ERRORED = "errored",
  RESET = "reset",
}

interface UploadStats {
  uploaded: number;
  updated: number;
  errored: number;
  current: UploadState;
}

interface StatAction {
  type: UploadState;
  payload?: {};
}

const initiaStat: UploadStats = {
  uploaded: 0,
  updated: 0,
  errored: 0,
  current: UploadState.RESET,
};

function uploadReducer(state: UploadStats, action: StatAction) {
  switch (action.type) {
    case UploadState.PROGRESS:
      return { ...state, current: UploadState.PROGRESS };
    case UploadState.FAILED:
      return { ...state, current: UploadState.FAILED };
    case UploadState.SUCCESS:
      return { ...state, current: UploadState.SUCCESS };
    case UploadState.UPLOADED:
      return { ...state, uploaded: state.uploaded + 1 };
    case UploadState.UPDATED:
      return { ...state, updated: state.updated + 1 };
    case UploadState.ERRORED:
      return { ...state, errored: state.errored + 1 };
    case UploadState.RESET:
      return { ...initiaStat };
    default:
      return state;
  }
}

type PostMutationResult = {
  id: number;
  enabled: true | false;
};

export const CardList: React.FC<IResourceComponentsProps> = () => {
  const { mutate } = useUpdateMany<PostMutationResult>();

  const { push } = useNavigation();
  const [uploadState, dispatch] = useReducer(uploadReducer, initiaStat);
  useEffect(() => {
    if (
      uploadState.current === UploadState.SUCCESS ||
      uploadState.current === UploadState.FAILED
    ) {
      if (uploadState.current === UploadState.SUCCESS) {
        message.success(
          `Upload completed. Stats: Uploaded: ${uploadState.uploaded}, Updated: ${uploadState.updated}, Errored: ${uploadState.errored}, reloading...`
        );
      }
      setTimeout(() => push("/"), 3000);
    }
  }, [push, uploadState]);

  useEffect(() => {
    if (window.sessionStorage.getItem("password_set")) {
      // session token set, redirect to password page to let the user set the password
      window.sessionStorage.removeItem("password_set");
      push("/password");
    }
  }, [push]);

  const { listProps, searchFormProps, filters } = useSimpleList<
    ICard,
    HttpError,
    {
      name: string;
      categories: string[];
      is_sold: boolean;
      no_price: boolean;
    }
  >({
    pagination: {
      pageSize: 30,
    },
    onSearch: ({ name, categories, is_sold, no_price }) => {
      const cardFilters: CrudFilters = [];

      cardFilters.push({
        field: "category",
        operator: "in",
        value: categories?.length > 0 ? categories : undefined,
      });

      cardFilters.push({
        field: "name",
        operator: "contains",
        value: name ? name : undefined,
      });

      cardFilters.push({
        field: "is_sold",
        operator: "eq",
        value: is_sold ? true : false,
      });

      //console.log(no_price);

      cardFilters.push({
        field: "start_range",
        operator: "eq",
        value: no_price ? 0 : undefined,
      });

      return cardFilters;
    },
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    initialFilter: [
      {
        field: "is_sold",
        operator: "eq",
        value: false,
      },
    ],
  });

  const processCSVRecords = async (records: [] | any) => {
    if (records?.length > 0) {
      const ItemsFromEbayPromises = new Array<any>();
      //lets convert foreach to for loop
      for(let i = 0; i < records.length; i++) {
        const record = records[i];
        if (record["Item number"]) {
          console.log("first item:");
          // if item exists in supabase then skip
          ItemsFromEbayPromises.push(getItemFromEbay(record["Item number"]));
          // TEMOPORARILY ENABLE ALREADY EXISTING ITEM CHECK [2-1]
          // const {data, error } = await supabaseClient.from("cards").select("*").eq("ebay_item_number", record["Item number"]);
          // if (!error && data && data.length > 0) {
          //   console.log("Item already exists");
          // } else {
          //   ItemsFromEbayPromises.push(getItemFromEbay(record["Item number"]));
          // }
          
        }
      }
      if (ItemsFromEbayPromises.length > 0) {
        // set all ebay items to sold [2-2]
        await supabaseClient
          .from("cards")
          .update({ is_sold: true, enabled: false })
          .neq("ebay_item_number", null);

        Promise.all(ItemsFromEbayPromises).then(function (ebayItems) {
          console.log(ebayItems);
          const processItems = new Array<any>();
          const syncWithSupabase = new Promise<void>((resolve) => {
            ebayItems.forEach((ebayItem) => {
              processItems.push(processEbayItem(ebayItem));
            });
            Promise.all(processItems).then(() => {
              console.log("All Done A");
              resolve();
            });
          });
          Promise.all([syncWithSupabase]).then(() => {
            console.log("All Done B");
            UpdateStats(UploadState.SUCCESS);
          });
        });
        console.log("finished");
      }
    }
  };

  const processEbayItem = async (ebayItem: ICard) => {
    const { error } = await supabaseClient.from("cards").insert(ebayItem);
    if (error) {
      const { error } = await supabaseClient
        .from("cards")
        .update(ebayItem)
        .eq("ebay_item_number", ebayItem.ebay_item_number);

      if (!error) {
        UpdateStats(UploadState.UPDATED);
      } else {
        UpdateStats(UploadState.ERRORED);
      }
    } else {
      UpdateStats(UploadState.UPLOADED);
    }
  };

  const UpdateStats = (actionType: UploadState) => {
    dispatch({ type: actionType });
  };

  function UploadError(err: Error) {
    message.error(err.message);
    dispatch({ type: UploadState.FAILED });
  }

  const cardSelected = async (id: number, enabled: boolean) => {
    console.log(id, enabled);
    mutate({
      resource: "cards",
      ids: [id],
      values: { enabled: enabled },
    });
  };

  // Edit Drawer
  const {
    formProps: editFormProps,
    drawerProps: editDrawerProps,
    show: editDrawerShow,
    saveButtonProps: editSaveButtonProps,
    id,
    formLoading,
    queryResult,
  } = useDrawerForm<ICard>({
    action: "edit",
    warnWhenUnsavedChanges: true,
  });

  return (
    <>
      <Form
        {...searchFormProps}
        onValuesChange={() => {
          searchFormProps.form?.submit();
        }}
        initialValues={{
          name: getDefaultFilter("name", filters, "contains"),
          categories: getDefaultFilter("categories.id", filters, "in"),
          is_sold: false,
          no_price: false,
        }}
      >
        <Row>
          <Col>
            <Form.Item name="categories">
              <CardCategoryFilter />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{}}>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: "8px",
                marginBottom: "16px",
                border: 2,
                borderColor: "gray",
                borderStyle: "solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <Text style={{ fontSize: "24px" }} strong>
                Cards
              </Text>

              <Form.Item name="name" noStyle>
                <Input
                  style={{
                    width: "400px",
                  }}
                  placeholder="Card Search"
                  suffix={<SearchOutlined />}
                />
              </Form.Item>
              <Form.Item
                name="is_sold"
                valuePropName="checked"
                label="Show Sold Items"
                noStyle
              >
                <Switch />
              </Form.Item>
              <Text>Show Sold Items</Text>
              <Form.Item
                name="no_price"
                valuePropName="checked"
                label="Show Non-Priced Items"
                noStyle
              >
                <Switch />
              </Form.Item>
              <Text>Show Non-Priced Items</Text>
              <CreateButton resource="cards">Add New Card</CreateButton>
              <Upload
                accept=".csv"
                customRequest={({ file }) => {
                  const parseFile = file as Papa.LocalFile;
                  dispatch({ type: UploadState.PROGRESS });
                  //Parse local CSV file
                  Papa.parse(parseFile, {
                    complete: function (results) {
                      console.log("Finished:", results.data);
                      processCSVRecords(results.data); //
                    },
                    error: function (err) {
                      UploadError(err);
                    },
                    header: true,
                  });
                  console.log(file);
                }}
                itemRender={() => <></>}
              >
                <Button
                  icon={<UploadOutlined />}
                  disabled={uploadState.current !== UploadState.RESET}
                >
                  {uploadState.current === UploadState.PROGRESS && (
                    <LoadingOutlined />
                  )}
                  {uploadState.current === UploadState.FAILED && (
                    <FileExcelOutlined />
                  )}
                  {uploadState.current === UploadState.SUCCESS && (
                    <FileDoneOutlined />
                  )}
                  Upload Ebay Products
                </Button>
              </Upload>
              {/* <Button 
                icon={<SyncOutlined />}
                onClick={async () => {
                  await syncEbayImages();
                }}
              >
                Sync Ebay Images
              </Button> */}

            </div>
          </Col>
        </Row>
        <AntdList
          grid={{
            column: 6,
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 4,
            xl: 4,
            xxl: 4,
          }}
          {...listProps}
          renderItem={(item) => (
            <AntdList.Item>
              <SingleCard
                record={item}
                onSelect={cardSelected}
                editDrawerShow={editDrawerShow}
              />
            </AntdList.Item>
          )}
        />
      </Form>
      <Drawer {...editDrawerProps}>
        <Edit
          recordItemId={id}
          saveButtonProps={{
            ...editSaveButtonProps,
            disabled: formLoading,
          }}
        >
          <Form
            {...editFormProps}
            layout="vertical"
            style={{ fontSize: "small" }}
          >
            <h3>{queryResult?.data?.data?.name}</h3>
            <Image width={150} src={queryResult?.data?.data?.image.url} />
            <br />
            {queryResult?.data?.data.price && (
              <h4> Ebay Price: ${queryResult?.data?.data.price}</h4>
            )}
            <Form.Item label="Currency" name="currency">
              <Radio.Group>
                <Radio.Button value="USD">USD</Radio.Button>
                <Radio.Button value="CAD">CAD</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Start Range" name="start_range">
              <InputNumber prefix="$" />
            </Form.Item>
            <Form.Item label="Low Range" name="low_range">
              <InputNumber prefix="$" />
            </Form.Item>
          </Form>
        </Edit>
      </Drawer>
    </>
  );
};
